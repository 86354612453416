import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import NewsletterForm from '../components/NewsletterForm'

const AboutPage = props => {
  return (
    <Layout>
      <SEO title='About' pathname='about' />
      <div className='about-me'>
        <div className='post container'>
          <h1>All About Me...</h1>
          <p>
            Hi{' '}
            <span role='img' aria-label=''>
              👋
            </span>
            ! I'm Ali Spittel, and I create all the stuff you see on this site. I'm a
            (mostly)
            <a
              target='_blank'
              href='https://youtu.be/9JNubiReEco'
              rel='noopener noreferrer'
            >
              &nbsp;self-taught software engineer
            </a>, who loves teaching people to
            code. I am currently a Senior Developer Advocate at Amazon Web Services on the
            <a
              href='https://welearncode.com/first-fullstack-app/' target='_blank'
              rel='noopener noreferrer'
            >
              &nbsp;Amplify team
            </a>. I'm obsessed with: my dog Blair, Taco Bell, and building art
            with JavaScript.
          </p>
          <img
            src='/me-and-blair.jpg'
            alt='Me and my dog Blair'
            style={{
              display: 'block',
              margin: '0 auto',
              maxWidth: '100%',
              width: '400px'
            }}
          />
          <h2>My Learn to Code Story</h2>
          <p>
            I grew up in a small town of 3,500 in rural New Hampshire - it
            doesn't even have a grocery store or stoplight! My high school was
            also tiny, and I graduated in a class of 90 people - there were only
            a few computers, and they were barely functional. All that to say -
            I had no idea what computer science was until I stepped into a
            computer science classroom my sophomore year of college thinking I
            was going to learn how to format Microsoft Word documents better{' '}
            <span aria-label=''>🤦🏼‍♀️</span>.
          </p>
          <p>
            <b>
              I quickly fell in love with Python; I could type something into a
              computer, and magic would come out.
            </b>{' '}
            I was having so much fun that I decided I was going to double major
            in computer science, and I even landed a teaching assistant position
            for that same intro to CS class.
          </p>
          <p>
            And then, the next semester, I took data structures and algorithms
            in C++. I was pulling all-nighters and still getting grades I wasn't
            happy about. Plus, I felt like I totally stuck out in that classroom
            - I hadn't been coding my whole life, and I was one of the few
            women.
          </p>
          <p>
            So, I quit coding. I thought I wasn't good at it, and that it wasn't
            for me.
          </p>
          <p>
            A couple of months later, I ended up in a data analysis internship,
            where I realized I could automate a lot of my job through scripting.
            From there, I got a software engineering job. Here we are almost
            seven years later, and I still write code pretty much every day!
          </p>
          <img
            src='/speaking.jpeg'
            alt="speaking to a crowd with the slide 'tech students to teach themselves'"
            width='700px'
            maxWidth='100%'
          />
          <h2>Why I created this Site</h2>
          <p>
            This site is for the Ali of a couple of years ago - and all the
            people like me. The people who have struggled at points while
            learning to code, and who sometimes feel like they don't belong in
            the tech industry.
          </p>
          <p>
            I love teaching code, and have had the privilege of doing so in a
            bunch of formats - from my student teaching semester in college to
            community events to teaching full time at a coding bootcamp{' '}
            <span role='img' aria-label=''>
              👩‍🏫
            </span>
            .
          </p>
          <p>
            <b>
              My goal: I want to make learning to code more accessible and fun
              for everyone. I have years of professional teaching and
              programming experience, and I want to use it to help you be the
              best programmer you can be.
            </b>
          </p>
          <p>
            I feel so lucky that my blog posts have been in front of well over a
            million eyes in the past year, and writing has completely changed my
            life.
          </p>
          <img
            src='/teaching.jpg'
            alt='Me teaching a classroom'
            style={{
              display: 'block',
              margin: '0 auto',
              maxWidth: '100%',
              width: '700px'
            }}
          />{' '}
          <h2>Still want to learn more?</h2>
          <p>
            You can read my more professional bio with my background, upcoming
            speaking gigs, interviews on other sites, and more{' '}
            <a
              href='https://alispit.tel'
              target='_blank'
              rel='noopener noreferrer'
            >
              &nbsp;on my portfolio site
            </a>
            .
          </p>
          <p>
            I also have a podcast with two other incredible women:{' '}
            <a
              href='https://ladybug.dev/'
              target='_blank'
              rel='noopener noreferrer'
            >
              &nbsp;Ladybug Podcast
            </a>
            . We talk about the tech industry, our backgrounds, and go in depth
            on code-topics. I also do weekly
            <a href='https://twitch.tv/aspittel/' target='_blank' rel='noopener noreferrer'>
            &nbsp;Twitch Streams
            </a> and <a
              href='https://youtube.com/alispitteldev'
              target='_blank'
              rel='noopener noreferrer'
            >
              &nbsp;YouTube videos
            </a>.
          </p>
          <NewsletterForm header={"Let's keep the conversation going!"} />
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
